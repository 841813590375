// colors
:root{
    // variable colors
    --shade-0: #ffffff;
    --shade-1: #f5f5f5;
    --shade-2: #f1f1f1;
    --shade-3: #dadddb;
    --shade-4: #d8d8d8;
    --shade-5: #acacac;
    --shade-6: #909090;
    --shade-7: #606060;
    --shade-8: #555555;

    --text-color: #333;
    --black: #222222;
    --background-color: #ffffff;
    --white: #ffffff;
    --white-dark-mode-only: transparent;
    --highlight-background: #f1f9fe;
    --popup-background: #ffffff;
    --popup-background-alt: #F5F5F5;
    // --tertiary-background: #FCE7EC;

    // fixed colors
    // --secondary: #008ae3;
    // --tertiary: #ef476f;
    --green: #5fbb97;
    --green-background: #E7F5F0;
    --orange: #ef8a17;
    --blue: #569ff9;
    --red: #DA3E52;
    .error-red{color: var(--red);}
    --red-background: #ffeded;
    --red-light: #ff7383;
    --white-fixed: #ffffff;
    --shade-1-fixed: #f5f5f5;

    --primary-color: #BF3E9C;
    --primary-color-lighter: #ED87D1;
    // --secondary: #7698B3;
    --secondary: #61A0AF;
    --link-blue: #647aa3;
    --gray: #959595;
    --lightgray: #bbbbbb;
    --border-gray: #d8d8d8;
    --tertiary: #BF3E9C;
    // --tertiary-background: #FFEBFA;
    --tertiary-background: #ffe4f8;
    // --quanternary: #4b8dc3;
    // --quanternary-background: #e4f3ff;
    --quanternary: #61A0AF;
    --quanternary-background: #DFECEF;
}

:root .theme--dark{
    --shade-0: #434343;
    --shade-1: #333333;
    --shade-2: #333333;
    --shade-3: #dadddb;
    --shade-4: #acacac;
    --shade-5: #acacac;
    --shade-6: #dadddb;
    --shade-7: #dadddb;
    --shade-8: #ffffff;

    --text-color: #ffffff;
    --background-color: #333333;
    --white: #1d1d1d;
    --highlight-background: #3F474C;
    --white-dark-mode-only: #ffffff;
    --popup-background: #383D44;
    --popup-background-alt: #2a3039;
    // --tertiary-background: #32222D;

    --primary-color: #BF3E9C;
    --secondary: #BF3E9C;
    --tertiary: #BF3E9C;
    --tertiary-background: #222f32;
}

// font sizes
$fontsize-xl: 28px;
$fontsize-l: 18px;
$fontsize-m: 16px;
$fontsize-s: 14px;
$fontsize-xs: 13px;
$fontsize-xxs: 12px;
// $fontsize-xl: 32px;
// $fontsize-l: 20px;
// $fontsize-m: 18px;
// $fontsize-s: 16px;
// $fontsize-xs: 14px;
// $fontsize-xxs: 12px;
$fontsize-display: 14px;

// dimensions
$sidePanelWidth: 400px;
$sidePanelWidth--1600: 340px;
$popoutPanelWidth: 440px;
$headerHeight: 48px;
$modalSidePanelWidth: 380px;
$modalSidePanelWidth--1600: 300px;

// spacing
$spacing-xxl: 64px;
$spacing-xl: 48px;
$spacing-l: 40px;
$spacing-m: 32px;
$spacing-s: 24px;
$spacing-xs: 16px;
$spacing-xxs: 8px;
$spacing-xxxs: 6px;